(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs')
        .controller('JobAddFormModalController', JobAddFormModalController);

    JobAddFormModalController.$inject = [
        'sourceJobAsset',
        'requestDataFactory',
        'confirmationModal',
        'jobGroupCollectionResponse',
        'partnershipCollectionResponse',
        'settingCollectionResponse',
        'jobGroupRequired',
        'siteResourceResponse',
        'siteRelationshipResponse',
        'priorityService',
        '$translate',
        'apiClient',
        'userResponse',
        '$uibModalInstance',
        'questionResponse',
        'sectionResponse',
        'locationCollectionResponse',
        '$q',
        'raiseRemedialJob',
        'sourceJob',
        'statutoryTypeCollectionResponse',
        'assetsCollectionResponse',
        '$rootScope',
        'documentTypesCollectionResponse',
        'documentTypeService',
        'elbSettings',
        'serviceProviderService'
    ];

    function JobAddFormModalController(
        sourceJobAsset,
        requestDataFactory,
        confirmationModal,
        jobGroupCollectionResponse,
        partnershipCollectionResponse,
        settingCollectionResponse,
        jobGroupRequired,
        siteResourceResponse,
        siteRelationshipResponse,
        priorityService,
        $translate,
        apiClient,
        userResponse,
        $uibModalInstance,
        questionResponse,
        sectionResponse,
        locationCollectionResponse,
        $q,
        raiseRemedialJob,
        sourceJob,
        statutoryTypeCollectionResponse,
        assetsCollectionResponse,
        $rootScope,
        documentTypesCollectionResponse,
        documentTypeService,
        elbSettings,
        serviceProviderService
        ) {

        var vm = this;
        vm.formErrors = false;
        vm.data = requestDataFactory.createRequest(
            {
                _links: {
                    location: !raiseRemedialJob ? sectionResponse.getLink('location') : null,
                    sourceJob: raiseRemedialJob ? sourceJob : null
                },
                files: {
                    links: []
                },
                siteContact: {
                    notifyOnCreate: true,
                    notifyOnComplete: true,
                    declineEmail: false
                },
                siteContactSameAsReporter: false,
                notifyOnComplete: userResponse.notifyOnComplete,
                notifyOnCreate: userResponse.notifyOnCreate,
                summary: !raiseRemedialJob ? questionResponse.summary  : '',
                description: !raiseRemedialJob ? questionResponse.description  : '',
                isRemedial: raiseRemedialJob,
                requiredDocumentTypes: { links: null }
            }
        );

        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;
        vm.SPWithBelowProsureRequirementsConfirmed = false;
        vm.selectedSite = null;
        vm.submitDisabled = false;
        vm.unsetStatutory = unsetStatutory;
        vm.changeJobGroup = changeJobGroup;
        vm.changeServiceProvider = changeServiceProvider;
        vm.changeOperative = changeOperative;
        vm.changeRelationship = changeRelationship;
        vm.submit = submitAction;
        vm.changeTenant = changeTenant;
        vm.changeStatutoryType = changeStatutoryType;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;
        vm.removeDocumentType = removeDocumentType;
        vm.jobGroups = jobGroupCollectionResponse;
        vm.jobGroupRequired = jobGroupRequired;
        vm.partnerships = partnershipCollectionResponse;
        vm.siteResourceResponse = siteResourceResponse;
        vm.poRequired = vm.siteResourceResponse.poRequired;
        vm.serviceProviderRequired = false;
        vm.canSeeTenants = siteResourceResponse.getLink('tenantLogging') !== null;
        vm.locationCollectionResponse = locationCollectionResponse;
        vm.locationRequired = siteResourceResponse.locationRequired;
        vm.showLocationParentAdd = false;
        vm.jobGroupChildSelectMandatory = settingCollectionResponse.jobGroupChildSelectMandatory;
        vm.statutoryTypes = statutoryTypeCollectionResponse;
        vm.assets = assetsCollectionResponse;
        vm.requireDocumentTypeWhenRaisingReactiveJob = settingCollectionResponse.requireDocumentTypeWhenRaisingReactiveJob;
        vm.siteRequiredDocumentTypeRequired = siteResourceResponse.excludeFromRequiringDocumentType;
        vm.data.assets = {
            asset: null,
            subasset: null
        };

        vm.selectedRequiredDocumentTypes = [];
        vm.selectedDocumentType = {};

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.requiredDocumentType = documentTypesCollectionResponse;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;
        vm.selectedRequiredDocumentTypeModel = {
            response: vm.requiredDocumentType,
            required: false,
            responseKeyPath: 'documentTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            linkParameters: {status: 'active'},
            onSelect: changeRequiredDocumentType,
            onRemove: changeRequiredDocumentType
        };

        if (vm.requireDocumentTypeWhenRaisingReactiveJob && !vm.siteRequiredDocumentTypeRequired) {
            vm.selectedRequiredDocumentTypes.push(vm.requireDocumentTypeWhenRaisingReactiveJob);
        }

        vm.certificateRequiredDocType = null;
        angular.forEach(vm.requiredDocumentType.documentTypes, function (value) {
            if (value.name === 'Certificate') {
                vm.certificateRequiredDocType = value;
            }
        });

        function changeRequiredDocumentType() {
            documentTypeService.changeRequiredDocumentType(vm);
        }

        function removeDocumentType(documentType) {
            documentTypeService.removeDocumentType(vm, documentType);
        }

        if (sourceJobAsset && sourceJobAsset.asset) {
            vm.data.assets.asset = {href: sourceJobAsset.asset.decode()};

            if (sourceJobAsset.subasset) {
                vm.data.assets.subasset = {href: sourceJobAsset.subasset.decode()};
            }
        }
        var reactiveJobApprover = siteResourceResponse.getLink('reactivejobapprover');
        var reactiveJobApproverBuddy = siteResourceResponse.getLink('buddy');

        if (
            siteResourceResponse.sharingEnabled ||
            !siteResourceResponse.reactiveJobApprovalRequired ||
            siteResourceResponse.getLink('reactivejobapprover') !== null &&
            (
                userResponse.getLink('self') === reactiveJobApprover ||
                userResponse.getLink('self') === reactiveJobApproverBuddy
            )
        ) {
            vm.serviceProviderRequired = true;
        }

        var priorityLinkParameters = {
            'active': true,
            'entityTypes': 'entity_type_all,entity_type_job',
            'order': 'name'
        };

        $uibModalInstance.rendered.then(function () {
            $rootScope.$emit('JobModalLoaded', true);
        });

        vm.selectedJobGroupModel = {
            response: vm.jobGroups,
            link: vm.jobGroups.getLink('self'),
            required: vm.jobGroupRequired,
            responseKeyPath: 'jobgroups',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeJobGroup,
            onRemove: vm.changeJobGroup,
            isHierarchical: true
        };

        vm.selectedServiceProviderModel = {
            response: vm.partnerships,
            link: vm.partnerships ? vm.partnerships.getLink('self') : null,
            linkParameters: {active:1},
            required: vm.serviceProviderRequired,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            formatValue: serviceProviderService.formatServiceProviderName,
            headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
            reorder: serviceProviderService.prosureGroupReorder,
            onSelect: vm.changeServiceProvider,
            onRemove: vm.changeServiceProvider
        };

        if (vm.prosureEnabled) {
            vm.selectedServiceProviderModel.loadmoreEnabled = true;
        }

        vm.selectedPriorityModel = {
            response: null,
            link: null,
            linkParameters: priorityLinkParameters,
            required: true,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority
        };

        vm.selectedOperativeModel = {
            response: null,
            link: null,
            required: false,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.title',
            onSelect: changeOperative,
            onRemove: changeOperative,
            includeEmbeddedData: 'serviceTypes',
            linkParameters: { permission: 'operative_permission_receive_jobs', includeServiceTypes: true, siteId: siteResourceResponse.id }
        };

        vm.selectedRelationshipModel = {
            response: siteRelationshipResponse,
            link: siteRelationshipResponse.getLink('self'),
            required: true,
            responseKeyPath: 'siterelationships',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeRelationship,
            onRemove: vm.changeRelationship
        };

        vm.selectedTenantContactModel = {
            response: null,
            link: siteResourceResponse.getLink('tenantLogging'),
            linkParameters: {'site': siteResourceResponse.getData().id, 'active': 'yes', 'order': 'name'},
            responseKeyPath: 'contacts',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'nameAndCompany',
            onSelect: vm.changeTenant,
            onRemove: vm.changeTenant
        };

        vm.selectedStatutoryTypeModel = {
            response: vm.statutoryTypes,
            link: vm.statutoryTypes.getLink('self'),
            required: true,
            responseKeyPath: 'statutorytypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeStatutoryType,
            onRemove: vm.changeStatutoryType
        };

        function changeStatutoryType() {
            if (vm.selectedStatutoryTypeModel.selected) {
                vm.data._links.statutorytype = {href: vm.selectedStatutoryTypeModel.selected.href};
                changeRequiredDocumentType();
            } else {
                delete vm.data._links.statutorytype;
                vm.selectedRequiredDocumentTypeModel.selected = null;
            }
        }

        function unsetStatutory() {
            delete vm.data._links.statutorytype;

            if (vm.certificateRequiredDocType) {
                removeDocumentType(vm.certificateRequiredDocType);
            }
        }

        priorityService.setupPriorities(null, null, siteResourceResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
            vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
        });

        function submitAction () {
            if(vm.prosureWarningMessage && !vm.SPWithBelowProsureRequirementsConfirmed) {
                vm.formErrors = true;

                return;
            }

            vm.formErrors = false;

            vm.data.requiredDocumentTypes.links = [];
            angular.forEach(vm.selectedRequiredDocumentTypes, function(value) {
                vm.data.requiredDocumentTypes.links.push({
                    href: value._links.self.href
                });
            });

            if (!raiseRemedialJob) {
                if (questionResponse.getLink('action')) {
                    vm.data._links.action = questionResponse.getLink('action');
                } else {
                    vm.data._links.auditquestion = questionResponse.getLink('self');
                }
            }

            if (!vm.isStatutory) {
                delete vm.data._links.statutorytype;
            }

            if (vm.data.assets) {
                if (vm.data.assets.asset) {
                    vm.data._links.asset = vm.data.assets.asset;
                }

                if (vm.data.assets.subasset) {
                    vm.data._links.subasset = vm.data.assets.subasset;
                } else {
                    delete vm.data._links.subasset;
                }
            } else {
                delete vm.data._links.asset;
                delete vm.data._links.subasset;
            }

            if (vm.selectedRequiredDocumentTypeModel.selected) {
                vm.data._links.requiredDocumentType = {href: vm.selectedRequiredDocumentTypeModel.selected.href};
            }

            return apiClient.create(vm.siteResourceResponse.getLink('create-reactive-job'), vm.data).then(function (response) {
                if (response) {
                    $uibModalInstance.close(response);
                    $rootScope.$broadcast('remedialCreated', null);
                }
            });
        }

        function changeJobGroup () {
            if (vm.selectedJobGroupModel.selected.hasOwnProperty('object')) {
                vm.data._links.jobgroup = {href: vm.selectedJobGroupModel.selected.href};
            } else {
                vm.data._links.jobgroup = null;
            }
        }

        function changeTenant () {
            if (vm.selectedTenantContactModel.selected && vm.selectedTenantContactModel.selected.object) {
                vm.selectedTenantContact = vm.selectedTenantContactModel.selected.object;

                vm.selectedRelationshipModel.selected = siteRelationshipResponse.siterelationships
                    .filter(function (item) {
                        return item.name === 'Occupier';
                    }).map(function(response){
                        return {
                            href:response._links.self.href,
                            object:response,
                            value:response.name
                        };
                    })[0];
                changeRelationship();

                vm.submitDisabled = true;

                $q.all([
                    apiClient.get(vm.selectedTenantContact.getLink('self')).then(function (response) {
                        if (typeof vm.data.contactTenant === 'undefined' || vm.data.contactTenant === null || vm.data.contactTenant.id != response.id) {
                            vm.data.contactTenant = vm.tenantContactResponse = response;
                            vm.data.contactTenant.phoneNumbers = [];
                            angular.forEach(response.contactInformation.fields, function (value, index) {
                                if (value.type === 'telephone') {
                                    vm.data.contactTenant.phoneNumbers.push(value);
                                }
                            });
                        }
                    })
                ]).then(function () {
                    vm.data._links.contactTenant = vm.selectedTenantContact.getLink('self');

                    if (vm.data.contactTenant.contactInformation.fields.length > 0) {
                        var contactInformation = vm.data.contactTenant.contactInformation.fields;
                        angular.forEach(contactInformation, function (contact) {
                            if (contact.type === 'email') {
                                vm.data.contactTenant.email = contact.value;
                            }
                            if (contact.type === 'telephone') {
                                vm.data.contactTenant.phone = contact.value;
                            }
                        });
                    } else {
                        vm.data.contactTenant.email = vm.selectedTenantContact.email;
                        vm.data.contactTenant.phone = vm.selectedTenantContact.phone;
                    }

                    vm.submitDisabled = false;
                });
            } else {
                vm.selectedRelationshipModel.selected = null;
                vm.data._links.contactTenant = vm.data.contactTenant = null;
            }
        }

        function changeServiceProvider () {
            vm.prosureWarningMessage = null;

            if (vm.selectedServiceProviderModel.selected && vm.selectedServiceProviderModel.selected.object) {
                vm.submitDisabled = true;

                if (vm.prosureEnabled) {
                    vm.prosureWarningMessage = serviceProviderService.setProsureWarningMessage(vm.selectedServiceProviderModel.selected);
                }

                apiClient.get(vm.selectedServiceProviderModel.selected.href).then(function (response) {
                    if (response) {
                        vm.selectedServiceProvider = response;
                        vm.selectedPriorityModel.loading = true;
                        vm.selectedOperativeModel.loading = true;
                        vm.data._links.serviceprovider = {href: vm.selectedServiceProvider.getLink('self')};
                        vm.selectedPriorityModel.selected = null;
                        vm.selectedOperativeModel.selected = null;
                        changePriority();

                        // Update priority options
                        priorityService.setupPriorities(vm.selectedServiceProviderModel.selected.object.getLink('priorities'),
                                                        vm.selectedServiceProvider.getLink('priorities'),
                                                        siteResourceResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
                            vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
                            vm.selectedPriorityModel.loading = false;
                        });

                        vm.submitDisabled = false;

                        if (vm.selectedServiceProvider.getLink('joboperatives')) {
                            apiClient.get(vm.selectedServiceProvider.getLink('joboperatives'), { permission: 'operative_permission_receive_jobs', includeServiceTypes: true, siteId: siteResourceResponse.id }).then(function(operativesResponse) {
                                if (operativesResponse) {
                                    vm.selectedOperativeModel.response = operativesResponse;
                                    vm.selectedOperativeModel.link = vm.selectedServiceProvider.getLink('joboperatives');
                                } else {
                                    vm.selectedOperativeModel.response = null;
                                }
                            });
                        }

                        vm.selectedOperativeModel.loading = false;

                        return;
                    }

                    return apiClient.noResourceFound();
                });
            } else {
                vm.selectedServiceProvider = null;
                vm.data._links.serviceprovider = null;
                vm.selectedPriorityModel.selected = null;
                vm.selectedOperativeModel.selected = null;
                changePriority();
                changeOperative();

                // Update priority options
                priorityService.setupPriorities(null, null, siteResourceResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
                    vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
                    vm.selectedPriorityModel.loading = false;
                });

            }

            vm.SPWithBelowProsureRequirementsConfirmed = false;
        }

        function changeOperative() {
            if (vm.selectedOperativeModel.selected && vm.selectedOperativeModel.selected.object) {
                vm.data._links.operative = {href: vm.selectedOperativeModel.selected.href};
            } else {
                delete vm.data._links.operative;
            }
        }

        function changePriority () {
            if (vm.selectedPriorityModel.selected && vm.selectedPriorityModel.selected.object) {
                vm.data._links.priority = {href: vm.selectedPriorityModel.selected.href};
                vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');

                if (vm.selectedPriorityModel.selected.object.alertMessageEnabled) {
                    confirmationModal.open({
                        titleMessage: 'PRIORITY_MESSAGE',
                        bodyMessage: vm.selectedPriorityModel.selected.object.alertMessage,
                        primaryText: 'OK',
                        primaryClass: 'btn-primary',
                        secondaryText: ''
                    });
                }

                if (vm.data.isOnNextPlannedVisit || vm.data.isByPlannedDate) {
                    delete vm.data._links.priority;
                }

                if (!vm.data.isByPlannedDate && !vm.data.isOnNextPlannedVisit) {
                    delete vm.data.attendanceDueAt;
                    delete vm.data.completionDueAt;
                }
            } else {
                delete vm.data._links.priority;
                vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;
            }
        }

        function changeRelationship () {
            if (vm.selectedRelationshipModel.selected && vm.selectedRelationshipModel.selected.object) {
                vm.selectedRelationship = vm.selectedRelationshipModel.selected.object;
                vm.data._links.sitecontactsiterelationship =  {href: vm.selectedRelationship.getLink('self')};
            } else {
                vm.data._links.sitecontactsiterelationship = null;
            }
        }
    }
})();
