(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminJobExtraSettingsController', AdminJobExtraSettingsController);

    function AdminJobExtraSettingsController($scope, apiClient, messenger, jobExtraSettings) {
        var vm = this;
            vm.settings = jobExtraSettings;
            vm.jobNotifySiteAssociateType = jobExtraSettings.getLinkAttribute('jobNotifySiteAssociateType','title') || null;
            vm.selectedDocumentType = vm.settings.requireDocumentTypeWhenRaisingReactiveJob;
    }
})();

